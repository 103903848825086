import React, { useEffect, useState } from 'react';
import authFetch from '../services/authFetch';
import '../styles/SuperuserPage.css';

function SuperuserPage() {
  const [usages, setUsages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [appliedStartDate, setAppliedStartDate] = useState('');
  const [appliedEndDate, setAppliedEndDate] = useState('');
  const [totalQueries, setTotalQueries] = useState(0);
  const [totalSpent, setTotalSpent] = useState(0);
  const [costTotal, setCostTotal] = useState(0);
  const [totalRecharges, setTotalRecharges] = useState(0); // Novo estado para armazenar recargas
  const [selectedUsage, setSelectedUsage] = useState(null);
  const [usageDetails, setUsageDetails] = useState([]); // Estado para armazenar os detalhes do usuário

  // Fetch Total Usage Data
  useEffect(() => {
    const fetchTotalUsages = async () => {
      try {
        let url = 'https://panel.zepesquisa.com.br/reports/usages-total/';
        if (appliedStartDate) url += `?start_date=${appliedStartDate}`;
        if (appliedEndDate) url += appliedStartDate ? `&end_date=${appliedEndDate}` : `?end_date=${appliedEndDate}`;

        const response = await authFetch(url);
        if (!response.ok) throw new Error('Erro ao buscar dados de consultas totais');

        const data = await response.json();
        setTotalQueries(data.total_queries);
        setTotalSpent(data.total_spent);
        setCostTotal(data.cost_total);
      } catch (err) {
        setError(err.message);
      }
    };
    fetchTotalUsages();
  }, [appliedStartDate, appliedEndDate]);

  // Fetch Total Recharges Data
  useEffect(() => {
    const fetchTotalRecharges = async () => {
      try {
        let url = 'https://panel.zepesquisa.com.br/reports/confirmed-payment/';
        if (appliedStartDate) url += `?start_date=${appliedStartDate}`;
        if (appliedEndDate) url += appliedStartDate ? `&end_date=${appliedEndDate}` : `?end_date=${appliedEndDate}`;

        const response = await authFetch(url);
        if (!response.ok) throw new Error('Erro ao buscar dados de recargas');

        const data = await response.json();
        setTotalRecharges(data.total_recharges);
      } catch (err) {
        setError(err.message);
      }
    };
    fetchTotalRecharges();
  }, [appliedStartDate, appliedEndDate]);

  // Fetch Usages Data
  useEffect(() => {
    const fetchUsages = async () => {
      try {
        let url = 'https://panel.zepesquisa.com.br/reports/usages/';
        if (appliedStartDate) url += `?start_date=${appliedStartDate}`;
        if (appliedEndDate) url += appliedStartDate ? `&end_date=${appliedEndDate}` : `?end_date=${appliedEndDate}`;

        const response = await authFetch(url);
        if (!response.ok) throw new Error('Erro ao buscar dados de uso');

        const data = await response.json();
        setUsages(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchUsages();
  }, [appliedStartDate, appliedEndDate]);

  // Apply Filters
  const applyFilters = () => {
    setAppliedStartDate(startDate);
    setAppliedEndDate(endDate);
  };

  // Clear Filters
  const clearFilters = () => {
    setStartDate('');
    setEndDate('');
    setAppliedStartDate('');
    setAppliedEndDate('');
    setSelectedUsage(null);
    setUsageDetails([]); // Limpa a seleção
  };

  // Format Currency
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  // Fetch Usage Details
  const fetchUsageDetails = async (userId) => {
    try {
      setLoading(true);
      const url = `https://panel.zepesquisa.com.br/reports/${userId}/details/`;
      const response = await authFetch(url);
      if (!response.ok) throw new Error('Erro ao buscar detalhes do usuário');

      const data = await response.json();
      setUsageDetails(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Handle Row Click - usa usage.user__id
  const handleRowClick = (usage) => {
    setSelectedUsage(usage);
    fetchUsageDetails(usage.user__id);
  };

  // Render Usages Table (tabela principal)
  const renderUsagesTable = (data) => (
    <div className="table-container">
      <table className="styled-table">
        <thead>
          <tr>
            <th>Email</th>
            <th>Total de Consultas</th>
            <th>Total de Erros</th>
            <th>Total de Sucessos</th>
            <th>Total Gasto</th>
          </tr>
        </thead>
        <tbody>
          {data.map((usage, index) => (
            <tr
              key={index}
              onClick={() => handleRowClick(usage)}
              className="clickable-row"
            >
              <td>{usage.user__email}</td>
              <td>{usage.total_queries}</td>
              <td>{usage.total_errors}</td>
              <td>{usage.total_success}</td>
              <td>{formatCurrency(usage.total_spent)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  // Render Dashboard
  return (
    <div className="superuser-container">
      <div className="filters-container">
        <div className="filter-item">
          <label>Data de Início:</label>
          <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} className="filter-input" />
        </div>
        <div className="filter-item">
          <label>Data de Fim:</label>
          <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} className="filter-input" />
        </div>
        <div className="filter-buttons">
          <button onClick={applyFilters} className="filter-btn">Aplicar Filtros</button>
          <button onClick={clearFilters} className="clear-btn">Limpar Filtros</button>
        </div>
      </div>

      <div className="dashboard-container">
        <div className="dashboard-box">
          <h3>Consultas Utilizadas</h3>
          <div className="dashboard-content">
            <div>Quantidade: <span>{totalQueries}</span></div>
            <div>Valor: <span>{formatCurrency(totalSpent)}</span></div>
          </div>
        </div>
        <div className="dashboard-box">
          <h3>Valor Pago</h3>
          <div className="dashboard-content">
            <div>Valor: <span>{formatCurrency(costTotal)}</span></div>
          </div>
        </div>
        <div className="dashboard-box">
          <h3>Lucro</h3>
          <div className="dashboard-content">
            <div>Valor: <span>{formatCurrency(totalSpent - costTotal)}</span></div>
          </div>
        </div>
        <div className="dashboard-box">
          <h3>Recargas</h3>
          <div className="dashboard-content">
            <div>Valor: <span>{formatCurrency(totalRecharges)}</span></div>
          </div>
        </div>
      </div>

      {loading && <p>Carregando dados...</p>}
      {error && <p>Erro: {error}</p>}
      {!loading && !error && usages.length > 0 && renderUsagesTable(usages)}
    </div>
  );
}

export default SuperuserPage;
